import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import './LatticePlot.css';

const LatticePlot = () => {
  const [data, setData] = useState([]);
  const [layout, setLayout] = useState({
    width: '400',
    height: '400',
    scene: {
      xaxis: {
        range: [-0.5, 2.5],
        showgrid: false,
        zeroline: false,
        visible: false,
      },
      yaxis: {
        range: [-0.5, 2.5],
        showgrid: false,
        zeroline: false,
        visible: false,
      },
      zaxis: {
        range: [-0.5, 2.5],
        showgrid: false,
        zeroline: false,
        visible: false,
      },
      bgcolor: '#2B2B2B',
      camera: {
        eye: { x: 1.5, y: 1.5, z: 1.5 },
        center: { x: 1, y: 1, z: 1 },
        up: { x: 0, y: 0, z: 1 },
      },
      dragmode: false,
    },
    plot_bgcolor: '#2B2B2B',
    paper_bgcolor: '#2B2B2B',
    margin: { l: 0, r: 0, b: 0, t: 0 },
    hovermode: false,
    autosize: true,
  });

  const latticePoints = (() => {
    const points = [];
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        for (let k = 0; k < 3; k++) {
          points.push([i, j, k]);
        }
      }
    }
    return points;
  })();

  const generateLine = () => {
    let points = [[1, 1, 1]];
    // Generate random points until the line has a length of at least 1
    while (points.length === 1) {
      const numSegments = Math.floor(Math.random() * 5) + 2;
      points = [[1, 1, 1]];
      for (let i = 0; i < numSegments; i++) {
        const point = [
          Math.floor(Math.random() * 3),
          Math.floor(Math.random() * 3),
          Math.floor(Math.random() * 3),
        ];
        // Only add the point if it's different from the previous point
        if (
          point[0] !== points[points.length - 1][0] ||
          point[1] !== points[points.length - 1][1] ||
          point[2] !== points[points.length - 1][2]
        ) {
          points.push(point);
        }
      }
    }
    const lineData = {
      x: points.map((point) => point[0]),
      y: points.map((point) => point[1]),
      z: points.map((point) => point[2]),
      mode: 'lines',
      line: { color: 'white', width: 2 },
      type: 'scatter3d',
      showlegend: false,
    };
    setData([lineData]);
  };

  useEffect(() => {
    const interval = setInterval(generateLine, 500);
    return () => clearInterval(interval);
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedHandleResize = debounce(handleResize, 100);

  window.addEventListener('resize', debouncedHandleResize);
  handleResize(); // Call handleResize initially to set the correct size

  return () => {
    window.removeEventListener('resize', debouncedHandleResize);
  };
}, []);

useEffect(() => {
  if (windowWidth <= 1000) {
    setLayout((prevLayout) => ({
      ...prevLayout,
      width: 225,
      height: 225,
    }));
  } else {
    setLayout((prevLayout) => ({
      ...prevLayout,
      width: 400,
      height: 400,
    }));
  }
}, [windowWidth]);

  const dotData = {
    x: latticePoints.map((point) => point[0]),
    y: latticePoints.map((point) => point[1]),
    z: latticePoints.map((point) => point[2]),
    mode: 'markers',
    marker: {
      size: 5,
      color: '#FF7324',
      opacity: 0.8,
    },
    type: 'scatter3d',
    showlegend: false,
  };

  const config = {
    displayModeBar: false,
  };

  return (
    <div className="lattice-plot">
      <div className="plot-container">
        <Plot data={[dotData, ...data]} layout={layout} config={config} />
      </div>
      <p className="paragraph2">134,217,728 POSSIBLE COMBINATIONS</p>
    </div>
  );
};

export default LatticePlot;