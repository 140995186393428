import React from 'react';
import { InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import './InputTable.css';

const InputTable = () => {
  const data = [
    { x: 2, y: '512' },
    { x: 3, y: '134217728' },
    { x: 4, y: '2.417*10^{24}' },
    { x: 5, y: '1.413*10^{73}' },
    { x: 6, y: '2.824*10^{219}' },
    { x: 1000, y: '2^{1.32*10^{477}}' },
  ];

  return (
    <div className="input-table">

      <h1 className='fancy2'>3X3 OBJECT</h1>

      <table>
        <thead>
          <tr>
            <th className='fancy'>DIMENSIONS</th>
            <th className='fancy'>POSSIBLE COMBINATIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.x}</td>
              <td>
                <InlineMath math={item.y} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h1 className='fancy1'>That is just for a 3x3 object, if we move up to 4x4, 5x5 then the number continues to get exponentially bigger making it impossible to break into.</h1>
    </div>
  );
};

export default InputTable;