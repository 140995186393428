import React, { useState, useEffect, useRef } from 'react';
import Web3 from 'web3';
import "./App.css";
import logoImage from './logonew.png';
import pdfImage from './pdf.png';
import twitterLogo from './Twitter_logo.png';
import LatticePlot from './LatticePlot';
import InputTable from './InputTable';
const API_URL = 'https://esabnioc.onrender.com';


const copyReferralLink = () => {
  const referralLinkInput = document.querySelector('.referral-link-box input');
  const copyText = document.querySelector('.copy-text');

  referralLinkInput.select();
  document.execCommand('copy');

  copyText.textContent = 'Copied!';
  copyText.classList.add('copied');

  setTimeout(() => {
    copyText.textContent = 'Click to copy';
    copyText.classList.remove('copied');
  }, 1000);
};

function App() {
  const [account, setAccount] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [referrer, setReferrer] = useState('');
  const [leaders, setLeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [myPoints, setMyPoints] = useState(null);
  const canvasRefs = useRef([]);
  const animationsRef = useRef([]);

  useEffect(() => {
    const fetchLeaders = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/api/leaders`);
        if (!response.ok) {
          throw new Error('Failed to fetch leaders');
        }
        const data = await response.json();
        setLeaders(data);
      } catch (error) {
        console.error('Fetch leaders error:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    
    

    fetchLeaders();
  }, []);


  // Function to parse query parameters using vanilla JavaScript
  useEffect(() => {
    const startAnimation = (canvas, duration) => {
      const ctx = canvas.getContext('2d');
      let animationId;
      let startTime;

      const boxRect = canvas.parentElement.getBoundingClientRect();
      const boxWidth = boxRect.width + 1;
      const boxHeight = boxRect.height;
      const borderWidth = 0; // Adjust this value to match your CSS border width
      const padding = 0; // Add padding to create space between the line and the border

      canvas.width = boxWidth - (borderWidth + padding) * 2;
      canvas.height = boxHeight - (borderWidth + padding) * 2;
      canvas.style.position = 'absolute';
      canvas.style.left = `${borderWidth + padding - 1}px`;
      canvas.style.top = `${borderWidth + padding - 1}px`;

      const totalDistance = 2 * (canvas.width + canvas.height);

      const animate = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;
        const progress = (elapsed / (duration * 1000)) * totalDistance;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = '#FF6B00';
        ctx.lineWidth = 4;
        ctx.beginPath();
        ctx.moveTo(Math.floor(0), Math.floor(0));

        const adjustedWidth = canvas.width;
        const adjustedHeight = canvas.height;

        if (progress <= adjustedWidth) {
          ctx.lineTo(Math.floor(progress), Math.floor(0));
        } else if (progress <= adjustedWidth + adjustedHeight) {
          ctx.lineTo(Math.floor(adjustedWidth), Math.floor(0));
          ctx.lineTo(Math.floor(adjustedWidth), Math.floor(progress - adjustedWidth));
        } else if (progress <= 2 * adjustedWidth + adjustedHeight) {
          ctx.lineTo(Math.floor(adjustedWidth), Math.floor(0));
          ctx.lineTo(Math.floor(adjustedWidth), Math.floor(adjustedHeight));
          ctx.lineTo(Math.floor(2 * adjustedWidth + adjustedHeight - progress), Math.floor(adjustedHeight));
        } else {
          ctx.lineTo(Math.floor(adjustedWidth), Math.floor(0));
          ctx.lineTo(Math.floor(adjustedWidth), Math.floor(adjustedHeight));
          ctx.lineTo(Math.floor(0), Math.floor(adjustedHeight));
          ctx.lineTo(Math.floor(0), Math.floor(2 * (adjustedWidth + adjustedHeight) - progress));
        }

        ctx.stroke();

        if (elapsed < duration * 1000) {
          animationId = requestAnimationFrame(animate);
        } else {
          startTime = timestamp;
          animationId = requestAnimationFrame(animate);
        }
      };

      animationId = requestAnimationFrame(animate);

      return () => {
        cancelAnimationFrame(animationId);
      };
    };

    const durations = [20, 120, 10]; // Specify the desired durations for each content box in seconds

    animationsRef.current = canvasRefs.current.map((canvasRef, index) => {
      if (canvasRef) {
        return startAnimation(canvasRef, durations[index]);
      }
    });

    const handleResize = () => {
      animationsRef.current.forEach((stopAnimation) => stopAnimation());
      animationsRef.current = canvasRefs.current.map((canvasRef, index) => {
        if (canvasRef) {
          return startAnimation(canvasRef, durations[index]);
        }
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      animationsRef.current.forEach((stopAnimation) => stopAnimation());
    };
  }, []);

  useEffect(() => {
    if (account) { // When the account is set, fetch the points for that account
      const fetchMyPoints = async () => {
        try {
          const response = await fetch(`${API_URL}/api/points/${account}`);
          if (!response.ok) {
            throw new Error('Failed to fetch my points');
          }
          const data = await response.json();
          setMyPoints(data.points);
        } catch (error) {
          console.error('Failed to fetch my points:', error);
        }
      };

      fetchMyPoints();
    }
  }, [account]); // Depend on account so it will re-run when account changes


  
  function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  }

  // When the component mounts, check if a referral code is present in the URL
  useEffect(() => {
    const referralCode = getQueryVariable("referralCode");
    if (referralCode) {
      setReferrer(referralCode);
    }
  }, []);

  const connectWalletHandler = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]); // Set the first account as the connected account

        // Setup the POST request body
        let requestBody = { address: accounts[0] };
        if (referrer) {
          requestBody.referralCode = referrer; // Use 'referralCode' instead of 'referrer'
        }

        console.log(`Sending to backend:`, requestBody); // Logging the request body

        // Call the backend API to store the wallet address or retrieve the existing referral code
        const response = await fetch(`${API_URL}/api/wallets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();
        console.log(`Response from server:`, data); // Logging the response from the server

        if (response.ok) {
          // Set the referral link in the state to be displayed
          setReferralLink(`http://esab.dev/?referralCode=${data.referralCode}`);
        } else {
          console.error('Error from server:', data);
          alert('Error connecting wallet. Please try again.');
        }
      } catch (error) {
        console.error('Error connecting to MetaMask:', error);
        alert('Error connecting wallet. Please make sure MetaMask is installed and you are connected to the correct network.');
      }
    } else {
      alert("Please install MetaMask!");
    }
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <div className="logo-container">
            <img src={logoImage} alt="Logo" className="logo" />
            <h1 className='logohead'>ESAB</h1>
          </div>
          <div className="wallet-section">
            <button className="connect-button" onClick={connectWalletHandler}>
              {account ? `${account.substring(0, 10)}...` : 'Connect Wallet'}
            </button>
            {account && (
              <span className="points">Your Points: {myPoints !== null ? myPoints : 'Loading...'}</span>
            )}
          </div>
        </div>
      </header>
      
      <main className="App-main">

      <div className="content-box1">
        <h1 className='header1'>THE QUANTUM PROOF BLOCKCHAIN</h1>
      </div>



      <div className="content-box2">
        <h1 className='header1'>THE ISSUE</h1>
        <p>Quantum computers are actively being developed, and it's only a matter of time before they become a significant threat. If a malicious user gains access to a quantum computer, the consequences could be devastating. Major blockchains like Ethereum and Bitcoin are at risk of being hacked through various methods, such as draining exchanges, double-spending, or unauthorized token creation. The arrival of quantum technology poses a serious danger to the security of existing blockchains. To protect the value of your tokens and ensure the integrity of the blockchain ecosystem, it is crucial that we take driven steps to make our blockchain quantum-proof.</p>
      </div>

      <h1 className='header1'>TYPES OF ATTACKS</h1>

      <div className="content-container">
        <div className="content-box">
        <canvas ref={(el) => (canvasRefs.current[0] = el)} className="border-animation"></canvas>
              <h1 className='header2'>51% ATTACK</h1>
              <p className='paragraph1'>With their power, Quantum Computers can control 51% of Ethereum's mining power, allowing double-spending and transaction manipulation.</p>
              <p className='header2'>APPROX TIME : 20s</p>
        </div>
        <div className="content-box">
        <canvas ref={(el) => (canvasRefs.current[1] = el)} className="border-animation"></canvas>
              <h1 className='header2'>PRIVATE KEY CRACK</h1>
              <p className='paragraph1'>Quantum computers could crack Ethereum's ECDSA, deriving private keys from public keys, enabling wallet theft and exchange theft causing billions to be lost.</p>
              <p className='header2'>APPROX TIME : 120s</p>
        </div>
        <div className="content-box">
        <canvas ref={(el) => (canvasRefs.current[2] = el)} className="border-animation"></canvas>
              <h1 className='header2'>SMART CONTRACT EXPLOIT</h1>
              <p className='paragraph1'>Quantum computers could identify and exploit vulnerabilities in Ethereum smart contracts, manipulating their behavior like executing functions.</p>
              <p className='header2'> APPROX TIME : 10s</p>
        </div>
      </div>
      <p className='paragraph3'>and many more</p>

      <div className="content-box3">
        <h1 className='header3'>THE SOLUTION</h1>
        <p className='paragraph1'>ESAB blockchain utilizes lattice-based cryptography with 1,000-dimensional lattices to safeguard against quantum attacks. The high-dimensional complexity makes it extremely effective at resisting future quantum threats, significantly enhancing ESAB's security. This approach positions us at the top of quantum-resistant blockchain technology, ensuring transaction safety well into the quantum computing era.</p>
        
        <div className='visualcontainer'>
          <LatticePlot />
          
          <InputTable />
        </div>
        
      </div>

      <div className='content-box4'>
          <h1 className='header1'>THE COIN</h1>
          <p className='paragraph1'>Our token will initially launch on the Base blockchain, leveraging its growing popularity and low transaction costs. As we develop our own blockchain, Base token holders will be airdropped the new native tokens once the blockchain is fully tested and operational.</p>
          <p className='paragraph1'>Our blockchain is being developed with a focus on speed, affordability, and quantum-proof security to protect your assets now and into the future. With the integration of smart contract capabilities and support for decentralized applications, our blockchain aims to provide a comprehensive and versatile platform for various use cases. </p>
      </div>


        <div className="content-boxog">
          <h1 className='header1'>REFERRAL LEADERBOARD</h1>
          {referralLink && (
            <div className="referral-link">
              <p>Your referral link:</p>
              <div className="referral-link-box" onClick={copyReferralLink}>
                <input type="text" value={referralLink} readOnly />
                <span className="copy-text">Click to copy</span>
              </div>
            </div>
          )}
          <div className="leaderboard-section">
            <h2>Leaderboard</h2>
            {isLoading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="error">Error fetching leaderboard: {error}</p>
            ) : (
              <ul className="leaderboard-list">
                {leaders.map((leader, index) => (
                  <li key={index}>
                    <span className="address">{leader.address}</span>
                    <span className="points">{leader.referrals} points</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </main>
      <div className='content-box5'>
        <a href="https://twitter.com/ESABchain" target="_blank" rel="noopener noreferrer" className="twitter-button">
          <img src={twitterLogo} alt="Twitter Logo" className="twitter-logo" />
        </a>
        <a href="https://esab.gitbook.io/esab" target="_blank" rel="noopener noreferrer">
          <img src={pdfImage} alt="docs" className="twitter-logo" />
        </a>
      </div>
    </div>
  );
}
export default App;
